import React from 'react';
import { connect } from 'react-redux'

import { isAuth, _setAxios } from '../../lib/Helper';
import AuthRedirect from '../../components/redirect/AuthRedirect'
import Preload from '../../components/preload/Preload'

import AlumniDetailInternal from './detail/Internal';
import AlumniDetailExternal from './detail/External';

class AlumniDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            type: null,
        };
    }

    getDataDiriByID = (id) => {
        this.setState({
            loading: true,
        })
        _setAxios("alumni/data-diri/" + id, "GET").then(resp => {
            if (resp.status === true) {
                const data = resp.data.data

                this.setState({
                    loading: false,
                    type: data.alumni_tipe ? data.alumni_tipe : null
                })
            } else {
                this.setState({
                    loading: false,
                })
            }
        })
    }

    componentDidMount() {
        this.getDataDiriByID(this.props.privilege.profile_ex.siswa_id)
    }

    render() {
        const access = this.props.privilege.access["/peserta-didik/alumni"]
        const action = access !== undefined ? access.action : null

        if (!isAuth(this.props.privilege) || access === undefined || !action.detail) {
            return <AuthRedirect />
        }

        if (this.state.loading) {
            return <div className="site-layout-background" style={{ padding: 24, minHeight: 660, margin: "24px 0" }}><Preload /></div>
        } else {
            if (this.state.type === "Alumni Internal") {
                return <AlumniDetailInternal action={action} {...this.props} />
            } else {
                return <AlumniDetailExternal action={action} {...this.props} />
            }
        }
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(AlumniDetail);