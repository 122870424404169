import React from 'react';
import { connect } from 'react-redux'

import { Row, Col, Divider, List, Spin, Dropdown, Menu, Button, Empty } from 'antd';
import { DownCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';

class Mobile extends React.Component {
  componentDidMount() {
    this.props.fetchTable(true)
  }

  render() {
    const { table } = this.props.state
    const action = this.props.access.action

    return (
      <>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <InfiniteScroll
              dataLength={table.data.length}
              next={this.props.fetchTable}
              hasMore={table.pagination.total != null && table.data.length < table.pagination.total}
              loader={<div style={{ textAlign: "center" }}><Spin size="small" /></div>}
              endMessage={
                table.loading_first ? "" : (table.data.length ? <Divider /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Data tidak ditemukan" />)
              }
            >
              <List
                loading={table.loading_first}
                locale={{ emptyText: " " }}
                dataSource={table.data}
                renderItem={(row, i) => (
                  <List.Item
                    key={i}
                  >
                    <List.Item.Meta
                      title={(row.nama ? row.nama : "-")}
                      description={<>
                        <Row gutter={[8, 8]}>
                          <Col xs={24}>
                            <small>
                              Tanggal: {row.tgl ? row.tgl : "-"} <br />
                              Kelas/Rombel: {row.rombel_nama ? row.rombel_nama : "-"} <br />
                              Jam Masuk: {row.waktu_masuk ? row.waktu_masuk : "-"} <br />
                              Jam Pulang: {row.waktu_pulang ? row.waktu_pulang : "-"} <br />
                              Durasi Telat: {row.durasi_telat ? row.durasi_telat : "-"} <br />
                              {/* Durasi Pulang Cepat: {row.durasi_pulang_cepat ? row.durasi_pulang_cepat : "-"} <br /> */}
                              Jarak Masuk: {row.radius_masuk ? parseFloat(row.radius_masuk).toFixed(2) + " Meter" : "-"} <br />
                              Jarak Pulang: {row.radius_pulang ? parseFloat(row.radius_pulang).toFixed(2) + " Meter" : "-"} <br />
                            </small>
                          </Col>
                        </Row>
                      </>}

                    />
                    <div>
                      {(!action.detail ? "-" : <Dropdown trigger={['click']} overlay={<Menu>
                        {action.detail ? (<Menu.Item key={0} onClick={this.props.modalDetail.bind(this, true, row)}><InfoCircleOutlined /> Detail</Menu.Item>) : ""}
                      </Menu>}>
                        <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                      </Dropdown>)}
                    </div>
                  </List.Item>
                )}
              />
            </InfiniteScroll>
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(Mobile);
