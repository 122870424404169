import React from 'react';
import { Row, Col, List, Skeleton } from 'antd';
import { _setAxios } from '../../../lib/Helper';

class TracingDetail extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isUpdate: false,
            loading: false,
            data: {
                statusKelulusan: [],
                contact: [],
                tracing: [],
            },
            ijazah: {
                file: null,
                visible: false
            }
        }
    }

    componentDidMount() {
        this.getDataDiriByID(this.props.dataID)
    }

    getDataDiriByID = (id) => {
        this.setState({
            loading: true,
        })
        _setAxios("alumni/data-diri/" + id, "GET").then(resp => {
            if (resp.status === true) {
                const data = resp.data.data

                this.setState({
                    loading: false,
                    data: {
                        data1: [{
                            title: 'No.Telp',
                            description: data.no_telp ? data.no_telp : "-",
                        }, {
                            title: 'E-Mail',
                            description: data.email ? data.email : "-",
                        }, {
                            title: 'Jenis Kelamin',
                            description: data.jenis_kelamin ? data.jenis_kelamin : "-",
                        }],

                        data2: [{
                            title: 'Tempat Lahir',
                            description: data.tempat_lahir ? data.tempat_lahir : "-",
                        }, {
                            title: 'Tanggal Lahir',
                            description: data.tgl_lahir ? data.tgl_lahir : "-",
                        }, {
                            title: 'Alamat',
                            description: (data.provinsi_nama ? (" " + data.provinsi_nama) : "") + (data.kabkot_nama ? ", " + data.kabkot_nama : "") + (data.kecamatan_nama ? ", " + data.kecamatan_nama : "") + (data.kelurahan_nama ? ", " + data.kelurahan_nama : " ") + (data.alamat ? ", " + data.alamat : "") + (data.kode_pos ? " (" + data.kode_pos + ")" : ""),
                        }],
                    }
                })
            } else {
                this.setState({
                    loading: false,
                })
            }
        })
    }

    render() {
        // const { action } = this.props
        const { loading, data } = this.state
        return (
            <>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12}>
                        {
                            !loading ?
                                <List
                                    itemLayout="horizontal"
                                    dataSource={data.data1}
                                    renderItem={item => (
                                        <List.Item>
                                            <List.Item.Meta
                                                title={item.title}
                                                description={item.description}
                                            />
                                        </List.Item>
                                    )}
                                />
                                :
                                <Skeleton />
                        }
                    </Col>
                    <Col xs={24} sm={12}>
                        {
                            !loading ?
                                <List
                                    itemLayout="horizontal"
                                    dataSource={data.data2}
                                    renderItem={item => (
                                        <List.Item>
                                            <List.Item.Meta
                                                title={item.title}
                                                description={item.description}
                                            />
                                        </List.Item>
                                    )}
                                />
                                :
                                <Skeleton />
                        }
                    </Col>
                </Row>
            </>
        )
    }
}


export default TracingDetail;