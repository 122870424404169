import React from "react";
// import { CircularProgress } from "@material-ui/core";
import { Spin } from 'antd';

const PreloadContent = () => {
    return (
       
        <div className="spin-loading-content">
            <Spin />
        </div>
    );
};
export default PreloadContent;