export const schemaPelanggaran = {
    tgl: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
    pelanggaran: {
        presence: { allowEmpty: false, message: 'harus dipilih' },
    },
}

export const schemaKonsultasi = {
    tgl: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
    uraian: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
}