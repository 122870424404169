import React from 'react';
import { Row, Col, Divider, List, Spin, Dropdown, Menu, Button, Empty } from 'antd';
import { CloseCircleOutlined, DownCircleOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';

class Mobile extends React.Component {
  componentDidMount() {
    this.props.fetchTable(true)
  }

  render() {
    const { table } = this.props.state
    const action = this.props.access.action

    return (
      <>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <InfiniteScroll
              dataLength={table.data.length}
              next={this.props.fetchTable}
              hasMore={table.pagination.total != null && table.data.length < table.pagination.total}
              loader={<div style={{ textAlign: "center" }}><Spin size="small" /></div>}
              endMessage={table.loading_first ? "" : (table.data.length ? <Divider /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Data tidak ditemukan" />)}
            >
              <List
                loading={table.loading_first}
                locale={{ emptyText: " " }}
                dataSource={table.data}
                renderItem={(row, i) => (
                  <List.Item
                    key={i}
                  >
                    <List.Item.Meta
                      title={row.nama_lengkap ? row.nama_lengkap : "-"}
                      description={<>
                        <Row gutter={[8, 8]}>
                          <Col xs={24}>
                            <small>
                              Kelas: {row.rombel_nama ? row.rombel_nama : "-"} <br />
                              Ekstrakurikuler: {row.ekstrakurikuler_nama ? row.ekstrakurikuler_nama : "-"} <br />
                              Nilai S1: {row.nilai_semester_ganjil ? row.nilai_semester_ganjil : "-"} <br />
                              Nilai S2: {row.nilai_semester_genap ? row.nilai_semester_genap : "-"} <br />
                              Tahun Ajaran: {row.tahun_ajaran ? row.tahun_ajaran : "-"} <br />
                            </small>
                          </Col>
                        </Row>
                      </>}

                    />
                    <div>
                      <Dropdown trigger={['click']} overlay={<Menu>
                        {action.delete ? (<Menu.Item key={2} onClick={this.props.modalDelete.bind(this, true, row)}><CloseCircleOutlined /> Delete</Menu.Item>) : ""}
                      </Menu>}>
                        <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                      </Dropdown>
                    </div>
                  </List.Item>
                )}
              />
            </InfiniteScroll>
          </Col>
        </Row>
      </>
    )
  }
}

export default Mobile;
