import React from 'react';
import { Row, Col, List, Divider, Skeleton, Image } from 'antd';
import { _setAxios } from '../../../lib/Helper';
import { DownloadOutlined } from '@ant-design/icons';

class TracingDetail extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isUpdate: false,
            loading: false,
            data: {
                statusKelulusan: [],
                contact: [],
                tracing: [],
            },
            ijazah: {
                file: null,
                visible: false
            }
        }
    }

    componentDidMount() {
        this.getDataTracingByID(this.props.dataID)
    }

    getDataTracingByID = (id) => {
        this.setState({
            loading: true,
        })
        _setAxios("alumni/tracing/" + id, "GET").then(resp => {
            if (resp.status === true) {
                const data = resp.data.data

                this.setState({
                    loading: false,
                    data: {
                        statusKelulusan: [
                            {
                                title: "Tahun Masuk",
                                description: data.tahun_masuk ? data.tahun_masuk : "-"
                            },
                            {
                                title: "Tahun Lulus",
                                description: data.tahun_lulus ? data.tahun_lulus : "-"
                            },
                            {
                                title: "File Ijazah",
                                description: <a target="_blank" download rel="noopener noreferrer" href={data.main_path + data.file_ijazah} style={{ fontSize: 11 }}>Download <DownloadOutlined /></a>
                            },
                        ],
                        contact: [{
                            title: "No. Telepon/Hp",
                            description: data.no_telp ? data.no_telp : "-"
                        }, {
                            title: "Alamat Sekarang",
                            description: data.alamat_sekarang ? data.alamat_sekarang : "-"
                        }],
                        tracing: [{
                            title: "Kategori",
                            description: data.kategori ? data.kategori : "-"
                        }, {
                            title: "Pendidikan Terkahir",
                            description: data.pendidikan_terakhir ? data.pendidikan_terakhir : "-"
                        }, {
                            title: "Nama Instansi Pendidikan",
                            description: data.pendidikan_instansi ? data.pendidikan_instansi : "-"
                        }, {
                            title: "Pekerjaan",
                            description: data.pekerjaan ? data.pekerjaan : "-"
                        }, {
                            title: "Nama Instansi/Perusahaan",
                            description: data.pekerjaan_instansi ? data.pekerjaan_instansi : "-"
                        }, {
                            title: "Alamat Instansi/Perusahaan",
                            description: data.alamat_instansi ? data.alamat_instansi : "-"
                        }],
                    }
                })
            } else {
                this.setState({
                    loading: false,
                })
            }
        })
    }

    render() {
        const { loading, data } = this.state
        return (
            <>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12}>
                        <Row gutter={[16, 16]}>
                            <Col xs={24}>
                                <Divider orientation="left"><h4>STATUS KELULUSAN</h4></Divider>
                                {
                                    !loading ?
                                        <List
                                            itemLayout="horizontal"
                                            dataSource={data.statusKelulusan}
                                            renderItem={item => (
                                                <List.Item>
                                                    <List.Item.Meta
                                                        title={item.title}
                                                        description={item.description}
                                                    />
                                                </List.Item>
                                            )}
                                        />
                                        :
                                        <Skeleton />
                                }
                            </Col>
                            <Col xs={24}>
                                <Divider orientation="left"><h4>KONTAK YANG DAPAT DIHUBUNGI</h4></Divider>
                                {
                                    !loading ?
                                        <List
                                            itemLayout="horizontal"
                                            dataSource={data.contact}
                                            renderItem={item => (
                                                <List.Item>
                                                    <List.Item.Meta
                                                        title={item.title}
                                                        description={item.description}
                                                    />
                                                </List.Item>
                                            )}
                                        />
                                        :
                                        <Skeleton />
                                }
                            </Col>
                        </Row>

                    </Col>
                    <Col xs={24} sm={12}>
                        <Divider orientation="left"><h4>TRACING</h4></Divider>
                        {
                            !loading ?
                                <List
                                    itemLayout="horizontal"
                                    dataSource={data.tracing}
                                    renderItem={item => (
                                        <List.Item>
                                            <List.Item.Meta
                                                title={item.title}
                                                description={item.description}
                                            />
                                        </List.Item>
                                    )}
                                />
                                :
                                <Skeleton />
                        }
                    </Col>
                </Row>

                <Image
                    width={200}
                    style={{ display: 'none' }}
                    src={this.state.ijazah.file}
                    preview={{
                        visible: this.state.ijazah.visible,
                        src: this.state.ijazah.file,
                        onVisibleChange: value => {
                            this.setState({
                                ijazah: {
                                    ...this.state.ijazah,
                                    visible: value
                                }
                            })
                        },
                    }}
                />
            </>
        )
    }
}


export default TracingDetail;