// PROD MAN
var config = {
    "base_url": "https://external.mandigital.man1gresik.sch.id",
    "host_api": "https://api.mandigital.man1gresik.sch.id",
    "api": "https://api.mandigital.man1gresik.sch.id/api",
    "landing_page": "https:/.mandigital.man1gresik.sch.id",
    "app_internal": "https://internal.mandigital.man1gresik.sch.id",
    "app_external": "https://external.mandigital.man1gresik.sch.id",
    "secret_key": "m4nD1g1t4l"
}

// TRAINING
// var config = {
//     "base_url": "https://external.training.mandigital.man1gresik.sch.id",
//     "host_api": "https://api.training.mandigital.man1gresik.sch.id",
//     "api": "https://api.training.mandigital.man1gresik.sch.id/api",
//     "landing_page": "https://training.mandigital.man1gresik.sch.id",
//     "app_internal": "https://internal.training.mandigital.man1gresik.sch.id",
//     "app_external": "https://external.training.mandigital.man1gresik.sch.id",
//     "secret_key": "m4nD1g1t4l"
// }

// DEV
// var config = {
//     "base_url": "https://external.dev.mandigital.man1gresik.sch.id",
//     "host_api": "https://api.dev.mandigital.man1gresik.sch.id",
//     "api": "https://api.dev.mandigital.man1gresik.sch.id/api",
//     "landing_page": "https://dev.mandigital.man1gresik.sch.id",
//     "app_internal": "https://internal.dev.mandigital.man1gresik.sch.id",
//     "app_external": "https://external.dev.mandigital.man1gresik.sch.id",
//     "secret_key": "m4nD1g1t4l"
// }

// LOCAL DEV
// var config = {
//     "base_url": "http://localhost:3000",
//     "host_api": "https://api.dev.mandigital.man1gresik.sch.id",
//     "api": "https://api.dev.mandigital.man1gresik.sch.id/api",
//     "landing_page": "https://dev.mandigital.man1gresik.sch.id",
//     "app_internal": "https://internal.dev.mandigital.man1gresik.sch.id",
//     "app_external": "https://external.dev.mandigital.man1gresik.sch.id",
//     "secret_key": "m4nD1g1t4l",
// }

export default config
