import React from 'react';
import { connect } from 'react-redux'

import { Row, Col, Divider, List, Spin, Empty } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { _fmtRupiah } from '../../../../lib/Helper';

class Mobile extends React.Component {
  componentDidMount() {
    this.props.fetchTable(true)
  }

  render() {
    const { table } = this.props.state

    return (
      <>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <InfiniteScroll
              dataLength={table.data.length}
              next={this.props.fetchTable}
              hasMore={table.pagination.total != null && table.data.length < table.pagination.total}
              loader={<div style={{ textAlign: "center" }}><Spin size="small" /></div>}
              endMessage={
                table.loading_first ? "" : (table.data.length ? <Divider /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Data tidak ditemukan" />)
              }
            >
              <List
                loading={table.loading_first}
                locale={{ emptyText: " " }}
                dataSource={table.data}
                renderItem={(row, i) => (
                  <List.Item
                    key={i}
                  >
                    <List.Item.Meta
                      title={row.pos_pemasukan_nama ? row.pos_pemasukan_nama : "-"}
                      description={<>
                        <Row gutter={[8, 8]}>
                          <Col xs={24}>
                            <small>
                              <b>Tanggal Bayar: </b>{row.tgl_transaksi ? row.tgl_transaksi : "-"} <br />
                              <b>Tahun Ajaran:</b> {row.tahun_ajaran ? row.tahun_ajaran : "-"} <br />
                              <b>Nominal Tagihan:</b> {"Rp. " + (row.nominal_tagihan ? _fmtRupiah(row.nominal_tagihan) : 0)} <br />
                              <b>Nominal Bayar:</b> {"Rp. " + (row.nominal_pembayaran ? _fmtRupiah(row.nominal_pembayaran) : 0)} <br />
                              <b>Jenis Pembayaran:</b> {row.jenis_pembayaran ? row.jenis_pembayaran : "-"} <br />
                              <b>File:</b> {row.bukti_pembayaran ? <a rel="noopener noreferrer" target='_blank' href={row.main_path + row.bukti_pembayaran} style={{ fontSize: 11 }} >Download <DownloadOutlined /></a> : "-"} <br />
                              <b>Keterangan:</b> {row.keterangan ? row.keterangan : "-"} <br />
                            </small>
                          </Col>
                        </Row>
                      </>}

                    />
                  </List.Item>
                )}
              />
            </InfiniteScroll>
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(Mobile);
