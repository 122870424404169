import React from 'react';
import { connect } from 'react-redux'

import { isAuth, _setAxios, getParamTable } from '../../../lib/Helper';
import AuthRedirect from '../../../components/redirect/AuthRedirect'

import { Table, Row, Col, PageHeader, Breadcrumb, List } from 'antd';
import { DownloadOutlined, DoubleRightOutlined } from '@ant-design/icons';

class HasilTestPesertaDidikDetail extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},

            table: {
                loading: false,
                data: [],
                search: "",
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                },
                sorting: [{
                    field: "id",
                    sort: "DESC",
                    urutan: 1
                }],
                filtering: {},
            },
            dataDetail: {
                data1: [
                    {
                        title: "NISN",
                        description: "-",
                    },
                    {
                        title: "Nama Peserta Didik",
                        description: "-",
                    },
                    {
                        title: "Jenis Kelamin",
                        description: "-",
                    },
                    {
                        title: "Rombel",
                        description: "-",
                    },
                ],
                total_poin: 0,
            },
            form: {
                visible: false,
                loading: false,
            },
            ddl: {
                pelanggaran: []
            },
            fileList: [],
            validateFile: false,

            dataID: this.props.privilege.profile_ex.siswa_id,
            rombelID: this.props.privilege.profile_ex.rombel_id,
        };
    }

    componentDidMount() {
        this.getDetailSiswa()
        this.fetchTable()
    }

    getDetailSiswa = () => {
        _setAxios("bk/hasil-tes-siswa/table-header/" + this.state.dataID + "/" + this.state.rombelID, "GET").then(resp => {
            if (resp.status === true) {
                const data = resp.data.data
                this.setState({
                    dataDetail: {
                        data1: [
                            {
                                title: "NISN",
                                description: data.nisn ? data.nisn : "-",
                            },
                            {
                                title: "Nama Peserta Didik",
                                description: data.nama ? data.nama : "-",
                            },
                            {
                                title: "Jenis Kelamin",
                                description: data.jenis_kelamin ? data.jenis_kelamin : "-",
                            },
                            {
                                title: "Rombel",
                                description: data.rombel_nama ? data.rombel_nama : "-",
                            },
                        ],
                    }
                })
            }
        })
    }

    fetchTable = () => {
        const { table } = this.state
        const params = getParamTable("default", table)
        this.fetch(params);
    }

    fetch = async (params = {}) => {
        this.setState({
            table: {
                ...params,
                loading: true
            }
        });
        _setAxios("bk/hasil-tes-siswa/table-riwayat/" + this.state.dataID, "POST", params).then(resp => {
            if (resp.status === true) {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                        data: resp.data.list,
                        pagination: {
                            ...params.pagination,
                            total: resp.data.info.total
                        }
                    }
                });
            } else {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                    }
                })
            }
        })
    };

    render() {
        const access = this.props.privilege.access["/bimbingan-konseling/hasil-tes"]
        const action = access !== undefined ? access.action : null

        if (!isAuth(this.props.privilege) || access === undefined || action.detail === false) {
            return <AuthRedirect />
        }

        const { table, dataDetail } = this.state;

        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    title={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Bimbingan Konseling</Breadcrumb.Item>
                        <Breadcrumb.Item>Hasil Tes Peserta Didik</Breadcrumb.Item>
                    </Breadcrumb>}
                />

                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>

                    <PageHeader
                        className="site-page-header"
                        title="Hasil Tes Peserta Didik"
                    />
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={18}>
                            <List
                                itemLayout="horizontal"
                                dataSource={dataDetail.data1}
                                renderItem={item => (
                                    <List.Item>
                                        <List.Item.Meta
                                            title={<><div style={{ float: "left", minWidth: 50 }}>{item.title}</div> : <small style={{ color: "rgb(119 105 105)" }}>{item.description}</small></>}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Col>
                        <Col xs={24}>
                            <PageHeader
                                className="site-page-header"
                                title="Riwayat Hasil Tes"
                            />
                        </Col>
                        <Col span={24}>
                            <Table
                                columns={[
                                    {
                                        title: 'No',
                                        dataIndex: 'rownum',
                                        width: '3%',
                                    },
                                    {
                                        title: 'Tanggal',
                                        dataIndex: 'tgl',
                                    },
                                    {
                                        title: 'Kategori',
                                        dataIndex: 'kategori_tes',
                                    },
                                    {
                                        title: 'Keterangan',
                                        dataIndex: 'keterangan',
                                    },
                                    {
                                        title: 'Dokumentasi',
                                        dataIndex: 'file',
                                        render: (a, row) => row.dokumen ? <a target="_blank" rel="noopener noreferrer" href={row.main_path + row.dokumen} style={{ fontSize: 11 }}>Download <DownloadOutlined /></a> : "-",
                                    },
                                ]}
                                rowKey={record => record.id}
                                dataSource={table.data}
                                pagination={table.pagination}
                                loading={table.loading}
                                onChange={(pagination) => {
                                    const params = getParamTable("change", table, null, null, pagination)
                                    this.fetch(params)
                                }}
                                size="small"
                            />
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(HasilTestPesertaDidikDetail);
