import React from 'react';
import { MobileView } from 'react-device-detect';
import { Popover, Button, Divider } from 'antd';
import { FilterOutlined, SortAscendingOutlined } from '@ant-design/icons';

import SortingTable from './SortingTable';
import { _scrollFloatBtn } from '../../lib/Helper';

class SortingTableMobileWithFilter extends React.Component {


  componentDidMount() {
    _scrollFloatBtn()
  }

  render() {
    const { table, sortFields } = this.props
    return (
      <MobileView>
        <div className='float-btn-center' id='float-btn-center' align='center'>
          <div className="float-btn-div">
            <Popover placement="top" content={<SortingTable fetch={this.props.fetch} table={table} sortFields={sortFields} />} trigger="click">
              <Button type='primary' className='float-btn' icon={<SortAscendingOutlined />}>Urutkan</Button>
            </Popover>
            <Divider type="vertical" className="divider-border-white" />
            <Button type='primary' className='btn-border-radius-left float-btn' onClick={this.props.modalFilter.bind(this, true)}><FilterOutlined />Filter</Button>
          </div>
        </div>
      </MobileView>
    )
  }
}

export default SortingTableMobileWithFilter
