import React from 'react';
import { connect } from 'react-redux'

import { isAuth, _setAxios } from '../../../lib/Helper';
import AuthRedirect from '../../../components/redirect/AuthRedirect'

import { Breadcrumb, Empty, PageHeader } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';

class ProfilBk extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            file_active: null
        };
    }

    componentDidMount() {
        this.getFileActive()
    }

    getFileActive = () => {
        _setAxios("bk/dokumen", "GET").then(resp => {
            if (resp.status === true) {
                const data = resp.data.data
                this.setState({
                    file_active: data && data.file ? data.main_path + data.file : null
                })
            }
        })
    }

    render() {
        const access = this.props.privilege.access["/bimbingan-konseling/profil-bk"]
        if (!isAuth(this.props.privilege) || access === undefined) {
            return <AuthRedirect />
        }

        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    title={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Bimbingan Konseling</Breadcrumb.Item>
                        <Breadcrumb.Item>Profil BK</Breadcrumb.Item>
                    </Breadcrumb>}
                />
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    {this.state.file_active ?
                        <embed src={this.state.file_active} type="application/pdf" style={{
                            width: "100%",
                            height: 720
                        }} />
                        : <Empty
                            imageStyle={{
                                height: 60,
                            }}
                            description={
                                <span>
                                    Mohon maaf, belum ada file profile BK yang aktif.
                                </span>
                            }
                        >
                        </Empty>}
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(ProfilBk);
