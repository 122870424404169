import React from 'react';
import { connect } from 'react-redux'

import { Table, Row, Col, Pagination } from 'antd';

import { _fmtRupiah, _tableLogActivity, getParamTable } from '../../../../../lib/Helper';

class Desktop extends React.Component {
  componentDidMount() {
    this.props.fetchTable()
  }

  render() {
    const { table } = this.props.state
    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Table
              columns={[
                {
                  title: 'No',
                  dataIndex: 'rownum',
                  width: '2%',
                }, {
                  title: 'Tahun Ajaran',
                  dataIndex: 'tahun_ajaran',
                }, {
                  title: 'Biaya',
                  dataIndex: 'tagihan_nama',
                }, {
                  title: 'Total Tagihan',
                  render: (a, row) => "Rp. " + (row.total_tagihan ? _fmtRupiah(row.total_tagihan) : 0)
                }, {
                  title: 'Biaya Opsional',
                  render: (a, row) => "Rp. " + (row.total_biaya_opsional ? _fmtRupiah(row.total_biaya_opsional) : 0)
                }, {
                  title: 'Total Pembayaran',
                  render: (a, row) => "Rp. " + (row.total_pembayaran ? _fmtRupiah(row.total_pembayaran) : 0)
                }, {
                  title: 'Belum Dibayar',
                  render: (a, row) => "Rp. " + (row.total_belum_dibayar ? _fmtRupiah(row.total_belum_dibayar) : 0)
                }, {
                  title: 'LogActivity',
                  key: 'operation',
                  width: '10%',
                  render: (a, row) => _tableLogActivity(row),
                },
              ]}
              rowKey={record => record.id}
              dataSource={table.data}
              pagination={false}
              loading={table.loading}
              size="small"
            />
          </Col>
          <Col xs={24} className="table-pagination">
            <Pagination
              size="small"
              current={table.pagination.current}
              pageSize={table.pagination.pageSize}
              total={table.pagination.total}
              showTotal={() => `Total ${table.pagination.total} items`}
              showSizeChanger
              onChange={(current, pageSize) => {
                const pagination = { current: current, pageSize: pageSize, total: table.pagination.total }
                const params = getParamTable("change", table, null, null, pagination)
                this.props.fetch(params)
              }}
            />
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(Desktop);
