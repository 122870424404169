import React from 'react';
import { MobileView } from 'react-device-detect';
import { Popover, Button } from 'antd';
import { SortAscendingOutlined } from '@ant-design/icons';

import SortingTable from './SortingTable';
import { _scrollFloatBtn } from '../../lib/Helper';

class SortingTableMobile extends React.Component {


  componentDidMount() {
    _scrollFloatBtn()
  }

  render() {
    const { table, sortFields } = this.props
    return (
      <MobileView>
        <div className='float-btn-center' id='float-btn-center' align='center'>
          <div className="float-btn-div">
            <Popover placement="top" content={<SortingTable fetch={this.props.fetch} table={table} sortFields={sortFields} />} trigger="click">
              <Button type='primary' className='float-btn' icon={<SortAscendingOutlined />}>Urutkan</Button>
            </Popover>
          </div>
        </div>
      </MobileView>
    )
  }
}

export default SortingTableMobile
