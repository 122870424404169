import React from 'react';
import { BrowserView } from 'react-device-detect';
import { Popover, Button } from 'antd';
import { FilterOutlined, SortAscendingOutlined } from '@ant-design/icons';

import SortingTable from './SortingTable';

class SortingTableDesktopWithFilter extends React.Component {

  render() {
    const { table, sortFields } = this.props
    return (
      <BrowserView>
        <Button.Group className='ant-btn-group-fullwidth'>
        <Button className='btn-border-radius-left' onClick={this.props.modalFilter.bind(this, true)}><FilterOutlined /></Button>

          <Popover placement="bottom" content={<SortingTable fetch={this.props.fetch} table={table} sortFields={sortFields}/>} trigger="click">
            <Button block className='btn-border-radius'><SortAscendingOutlined /> Urutkan</Button>
          </Popover>
        </Button.Group>
      </BrowserView>
    )
  }
}

export default SortingTableDesktopWithFilter
