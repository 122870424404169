import React from 'react';
import { Row, Col, Skeleton } from 'antd';
import { BellFilled } from '@ant-design/icons';
import moment from 'moment';

class Welcome extends React.Component {

  render() {
    const { data, loading } = this.props
    return (
      loading ? <Skeleton.Input active={true} className="skeleton-welcome" /> :
        <div className="dashboard-column dashboard-column-1">
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <div className='dashboard-column-1-ucapan'>Selamat Datang, <span style={{ color: "#10B582" }}>{data.profile.name} <span role="img" aria-label="say-hi">👋</span></span></div>
              <div style={{ color: "rgba(0, 0, 0, 0.45)" }}>Hari ini</div>
              <div style={{ color: "#262626" }}>{moment().format("dddd, D MMMM YYYY")}</div>
              <div style={{
                width: 50,
                height: 2,
                background: "#FF4D4F",
                margin: "16px 0 16px 0"
              }}></div>

              {
                data.profile_ex && data.profile_ex.siswa_alasan_akun_ditangguhkan ?
                  <div className='dashboard-column-1-role'><span style={{
                    background: "#FFF1F0",
                    padding: "8px 11px",
                    borderRadius: 50,
                    marginRight: 15
                  }}><BellFilled /></span> {data.profile_ex.siswa_alasan_akun_ditangguhkan} </div>
                  : ""
              }
            </Col>
          </Row>
        </div>
    )
  }
}


export default Welcome