import React from 'react';
import { Modal,  Divider, List, Typography } from 'antd';
const { Title } = Typography;

class Detail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        };
    }

    render() {
        const { data } = this.props
        let listData = [
            "Judul: " + (data.judul ? data.judul : "-"),
            "Jenis Agenda: " + (data.jenis_agenda ? data.jenis_agenda : "-"),
            "Uraian: " + (data.uraian ? data.uraian : "-"),
            "Civitas Akademik: " + (data.civitas_akademika ? data.civitas_akademika : "-"),
        ]
        return (
            <Modal
                title={"Informasi Kalender"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                onOk={this.props.hideModal}
            >
                <div style={{ textAlign: "center", fontWeight: "bold" }}>
                    <Title level={3}>{data.tgl_awal}&nbsp;&nbsp;-&nbsp;&nbsp;{data.tgl_akhir}</Title>
                </div>
                <Divider />
                <List
                    size="small"
                    dataSource={listData}
                    renderItem={(item) => <List.Item>{item}</List.Item>}
                />

            </Modal>
        )
    }
}

export default Detail;
