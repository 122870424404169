import React from 'react';
import { connect } from 'react-redux'
import {  _success, _validationFormMsg, _setAxios, _beforeUploadImage, _getBase64, _getBase64Upload } from '../../../lib/Helper';
import { agamaOptions, genderOptions, imunisasiOptions, praSchoolOptions, statusTempatTinggalOptions, transportasiOptions, jarakTempuhOptions, waktuTempuhOptions, kewarganegaraanOptions, biayaDariOptions, kebutuhanKhususOptions, kebutuhanDisabilitasOptions, citacitaOptions, hobiOptions } from '../../../data/options';
import { schemaDataDiri } from '../schema';
import { noImage } from '../../../data/image';
import validate from 'validate.js';
import { Button, message, Row, Col, Checkbox, Divider, Image, Radio, Skeleton, Upload, List, Tooltip, Alert } from 'antd';
import { PlusOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import Autocomplete from '@mui/material/Autocomplete';
import ImgCrop from 'antd-img-crop';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import Stack from '@mui/material/Stack';

class DataDiri extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isUpdate: false,
      loading: true,
      dataDiri: {
        imageProfile: null,
        data1: [],
        data2: [],
        data3: [],
      },

      values: {},
      errors: {},
      isValid: false,

      loadingBtn: false,
      loadingImage: false,

      previewImage: '',
      previewTitle: '',
      fileList: [],
      visible: false,
      validateImage: false,

      ddl: {
        regions: [],
        villages: [],
      },
    };
  }

  componentDidMount() {
    this.getSiswa()
  }

  getSiswa = () => {
    this.setState({
      loading: true,
    })
    _setAxios("siswa/data-diri/" + this.props.dataID, "GET").then(resp => {
      if (resp.status === true) {
        const data = resp.data.data

        let dataDiri = {
          imageProfile: data.main_path + data.foto,
          data1: [{
            title: 'NISN',
            description: data.nisn ? data.nisn : "-",
          },
          {
            title: 'NIS',
            description: data.nis ? data.nis : "-",
          },
          {
            title: 'No.KIP',
            description: data.no_kip ? data.no_kip : "-",
          },
          {
            title: 'NIK',
            description: data.nik ? data.nik : "-",
          }],

          data2: [{
            title: 'Nama Peserta Didik',
            description: data.nama ? data.nama : "-",
          },
          {
            title: 'Jenis Kelamin',
            description: data.jenis_kelamin ? data.jenis_kelamin : "-",
          },
          {
            title: 'Tempat Lahir',
            description: data.tempat_lahir ? data.tempat_lahir : "-",
          },
          {
            title: 'Tanggal Lahir',
            description: data.tgl_lahir ? data.tgl_lahir : "-",
          },
          {
            title: 'Kewarganegaraan',
            description: data.kewarganegaraan ? data.kewarganegaraan : "-",
          },
          {
            title: 'Agama',
            description: data.agama ? data.agama : "-",
          },
          {
            title: 'No.KK',
            description: data.no_kk ? data.no_kk : "-",
          },
          {
            title: 'Nama Kepala Keluarga',
            description: data.kepala_keluarga ? data.kepala_keluarga : "-",
          },
          {
            title: 'No.Akta Kelahiran',
            description: data.no_akta_kelahiran ? data.no_akta_kelahiran : "-",
          },
          {
            title: 'No.KKS',
            description: data.no_kks ? data.no_kks : "-",
          },
          {
            title: 'No.PKH',
            description: data.no_pkh ? data.no_pkh : "-",
          },
          {
            title: 'Jumlah Saudara',
            description: data.jml_saudara ? data.jml_saudara : "-",
          },
          {
            title: 'Anak Ke-',
            description: data.anak_ke ? data.anak_ke : "-",
          },
          {
            title: 'No.Telepon',
            description: data.no_telp ? data.no_telp : "-",
          },
          {
            title: 'Email',
            description: data.email ? data.email : "-",
          },
          {
            title: 'Cita-cita',
            description: data.cita_cita ? data.cita_cita : "-",
          },
          {
            title: 'Hobi',
            description: data.hobi ? data.hobi : "-",
          }],

          data3: [{
            title: 'Alamat',
            description: (data.provinsi_nama ? (" " + data.provinsi_nama) : "") + (data.kabkot_nama ? ", " + data.kabkot_nama : "") + (data.kecamatan_nama ? ", " + data.kecamatan_nama : "") + (data.kelurahan_nama ? ", " + data.kelurahan_nama : "") + (data.alamat ? data.alamat : "") + (data.kode_pos ? " (" + data.kode_pos + ")" : ""),
          },
          {
            title: 'Titik Koordinat',
            description: data.lat && data.long ? (data.lat ? data.lat : 0) + ", " + (data.long ? data.long : 0) : "-",
          },
          {
            title: 'Status Tempat Tinggal',
            description: data.status_tempat_tinggal ? data.status_tempat_tinggal : "-",
          },
          {
            title: 'Transportasi ke Sekolah',
            description: data.transportasi ? data.transportasi : "-",
          },
          {
            title: 'Jarak Tempuh',
            description: data.jarak_tempuh ? data.jarak_tempuh : "-",
          },
          {
            title: 'Waktu Tempuh',
            description: data.waktu_tempuh ? data.waktu_tempuh : "-"
          },
          {
            title: 'Yang Membiayai Sekolah',
            description: data.biaya_dari ? data.biaya_dari : "-"
          },
          {
            title: 'Kebutuhan Khusus',
            description: data.kebutuhan_khusus ? data.kebutuhan_khusus : "-"
          },
          {
            title: 'Kebutuhan Disabilitas',
            description: data.kebutuhan_disabilitas ? data.kebutuhan_disabilitas : "-"
          },
          {
            title: 'Berat Badan (Kg)',
            description: data.berat_badan ? data.berat_badan : "-",
          },
          {
            title: 'Tinggi Badan (Cm)',
            description: data.tinggi_badan ? data.tinggi_badan : "-",
          },
          {
            title: 'Lingkar Kepala (Cm)',
            description: data.lingkar_kepala ? data.lingkar_kepala : "-",
          },
          {
            title: 'Pendidikan Pra Sekolah',
            description: data.pendidikan_pra_sekolah ? (data.pendidikan_pra_sekolah.replace(/;/g, ", ")) : "-",
          },
          {
            title: 'Imunisasi',
            description: data.imunisasi ? (data.imunisasi.replace(/;/g, ", ")) : "-",
          }],
        }

        this.setState({
          isUpdate: false,
          loadingBtn: false,
          loading: false,
          dataDiri: dataDiri,
          fileList: data.foto ? [{
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: data.foto ? data.main_path + data.foto : noImage,
          }] : [],
          values: {
            nik: data.nik,
            nisn: data.nisn,
            nis: data.nis,
            no_kip: data.no_kip,
            nama: data.nama,
            jenis_kelamin: data.jenis_kelamin,
            tempat_lahir: data.tempat_lahir,
            tgl_lahir: data.tgl_lahir,
            kewarganegaraan: data.kewarganegaraan ? {
              value: data.kewarganegaraan,
              label: data.kewarganegaraan,
            } : null,
            agama: data.agama ? {
              value: data.agama,
              label: data.agama,
            } : null,
            no_kk: data.no_kk,
            kepala_keluarga: data.kepala_keluarga,
            no_akta_kelahiran: data.no_akta_kelahiran,
            no_kks: data.no_kks,
            no_pkh: data.no_pkh,
            jml_saudara: data.jml_saudara,
            anak_ke: data.anak_ke,
            no_telp: data.no_telp,
            email: data.email,
            cita_cita: data.cita_cita ? {
              value: data.cita_cita,
              label: data.cita_cita,
            } : null,
            hobi: data.hobi ? {
              value: data.hobi,
              label: data.hobi,
            } : null,
            lat: data.lat,
            long: data.long,
            status_tempat_tinggal: data.status_tempat_tinggal ? {
              value: data.status_tempat_tinggal,
              label: data.status_tempat_tinggal,
            } : null,
            transportasi: data.transportasi ? {
              label: data.transportasi,
              value: data.transportasi,
            } : null,
            jarak_tempuh: data.jarak_tempuh ? {
              value: data.jarak_tempuh,
              label: data.jarak_tempuh,
            } : null,
            waktu_tempuh: data.waktu_tempuh ? {
              value: data.waktu_tempuh,
              label: data.waktu_tempuh,
            } : null,
            biaya_dari: data.biaya_dari ? {
              value: data.biaya_dari,
              label: data.biaya_dari,
            } : null,
            kebutuhan_khusus: data.kebutuhan_khusus ? {
              value: data.kebutuhan_khusus,
              label: data.kebutuhan_khusus,
            } : null,
            kebutuhan_disabilitas: data.kebutuhan_disabilitas ? {
              value: data.kebutuhan_disabilitas,
              label: data.kebutuhan_disabilitas,
            } : null,
            berat_badan: data.berat_badan,
            tinggi_badan: data.tinggi_badan,
            lingkar_kepala: data.lingkar_kepala,
            pendidikan_pra_sekolah: data.pendidikan_pra_sekolah ? data.pendidikan_pra_sekolah.split(";") : null,
            imunisasi: data.imunisasi ? data.imunisasi.split(";") : null,
            alamat: data.alamat,
            kode_pos: data.kode_pos,
            region: data.kecamatan_id ? {
              kecamatan_id: data.kecamatan_id,
              kecamatan_nama: data.kecamatan_nama,
              kabkot_id: data.kabkot_id,
              kabkot_nama: data.kabkot_nama,
              provinsi_id: data.provinsi_id,
              provinsi_nama: data.provinsi_nama
            } : null,
            village: data.kelurahan_id ? {
              id: data.kelurahan_id,
              nama: data.kelurahan_nama
            } : null
          }
        })
      } else {
        this.setState({
          isUpdate: false,
          loading: false,
        })
      }
    })
  }

  // DDL START ------------------------------------------------------------------------------------------------------------------------

  getRegionDDL = (name, search = "") => {
    _setAxios("region", "POST", {
      "pagination": {
        "current": 1,
        "pageSize": 10
      },
      "search": search
    }).then(resp => {
      if (resp.status === true) {
        this.setState({
          ddl: {
            ...this.state.ddl,
            regions: resp.data.list
          }
        });
      }
    })
  }

  getVillageDDL = (name, districtID) => {
    _setAxios("region/" + districtID, "POST", {
      pagination: {
          current: 1,
          pageSize: 30
      },
  }).then(resp => {
      if (resp.status === true) {
        this.setState({
          ddl: {
            ...this.state.ddl,
            villages: resp.data.data
          }
        });
      }
    })
  }

  // DDL END ---------------------------------------------------------------------------------------------------------------------------

  // FILE IMAGE UPLOAD START ---------------------------------------------------------------------------------------------------------------------------
  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await _getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      visible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  handleChangeImage = ({ fileList }) => {
    if (this.state.validateImage === true) {
      fileList.forEach((row, i) => {
        fileList[i].status = "done"
      });
    }

    var foto = this.state.values.foto
    if (fileList.length > 0) {
      foto = fileList[0].thumbUrl
    }
    this.setState({
      fileList,
      values: {
        ...this.state.values,
        foto: foto
      },
    })
  };

  beforeUploadImage = (file) => {
    const validate = _beforeUploadImage(file)
    if (validate === false) {
      this.setState({ validateImage: false })
    } else {
      this.setState({ validateImage: true })
    }
    return true
  };

  // FILE IMAGE UPLOAD END ---------------------------------------------------------------------------------------------------------------------------

  // HANDLE FORM START ---------------------------------------------------------------------------------------------------------------------------
  handleChange = (e) => {
    const { values } = this.state;
    const { name, value } = e.target;

    if (name === "ayah_domisili" && value === "Luar Negeri") {
      values.ayah_region = null
      values.ayah_village = null
      values.ayah_kode_pos = null
    } else if (name === "ibu_domisili" && value === "Luar Negeri") {
      values.ibu_region = null
      values.ibu_village = null
      values.ibu_kode_pos = null
    } else if (name === "wali_domisili" && value === "Luar Negeri") {
      values.wali_region = null
      values.wali_village = null
      values.wali_kode_pos = null
    }

    this.setForm(values, name, value)
  };

  handleChangeSetValue = (name, value) => {
    const { values } = this.state;

    if (value && value instanceof Date && (name === "tgl_lahir" || name === "ayah_tgl_lahir" || name === "ibu_tgl_lahir" || name === "wali_tgl_lahir" || name === "tgl_masuk" || name === "tgl_keluar")) {
      value = value.getFullYear() + "-" + (value.getMonth() <= 9 ? "0" + value.getMonth() : value.getMonth()) + "-" + (value.getDate() <= 9 ? "0" + value.getDate() : value.getDate())
    } else if (name === "region" || name === "ayah_region" || name === "ibu_region" || name === "wali_region") {
      switch (name) {
        case "region":
          values["village"] = null;
          break;
        case "ayah_region":
          values["ayah_village"] = null;
          break;
        case "ibu_region":
          values["ibu_village"] = null;
          break;
        case "wali_region":
          values["wali_village"] = null;
          break;

        default:
          break;
      }
      if (value) {
        this.getVillageDDL(name, value.kecamatan_id)
      }
    }

    this.setForm(values, name, value)
  };

  setForm = (values, name, value, row = {}) => {
    values[name] = value;

    this.setValidate(values)
  }

  setValidate = (values) => {

    const errors = validate(values, schemaDataDiri);
    this.setState({
      values: values,
      errors: errors || {},
    });

    return errors
  }

  hasError = field => {
    return this.state.errors[field] ? true : false;
  }
  // HANDLE FORM END ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------


  // SUBMIT START ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  onSubmitDataDiri = async () => {
    const { values } = this.state;

    //Validation Siswa
    let validation = this.setValidate(values, schemaDataDiri)
    if (validation !== undefined) {
      return _validationFormMsg(validation)
    }

    let fileList = this.state.fileList
    let foto = null
    if (fileList.length > 0) {
      if (fileList[0].url && (fileList[0].url.includes("https://") || fileList[0].url.includes("http://"))) {
      } else {
        foto = await _getBase64(fileList[0].originFileObj)
        foto = _getBase64Upload(foto)
      }
    }

    let tglLahir = values.tgl_lahir ? values.tgl_lahir : null
    if (values.tgl_lahir && values.tgl_lahir instanceof Date) {
        const tgl = values.tgl_lahir.getDate() <= 9 ? "0" + values.tgl_lahir.getDate() : values.tgl_lahir.getDate()
        const bln = values.tgl_lahir.getMonth() <= 9 ? "0" + values.tgl_lahir.getMonth() : values.tgl_lahir.getMonth()
        const thn = values.tgl_lahir.getFullYear()

        tglLahir = tgl + "-" + bln + "-" + thn
    }

    const params = {
      provinsi_id: values.region ? values.region.provinsi_id : null,
      kabkot_id: values.region ? values.region.kabkot_id : null,
      kecamatan_id: values.region ? values.region.kecamatan_id : null,
      kelurahan_id: values.village ? values.village.id : null,
      nisn: values.nisn ? values.nisn : null,
      nis: values.nis ? values.nis : null,
      no_kip: values.no_kip ? values.no_kip : null,
      nik: values.nik ? values.nik : null,
      nama: values.nama ? values.nama : null,
      jenis_kelamin: values.jenis_kelamin ? values.jenis_kelamin : null,
      tempat_lahir: values.tempat_lahir ? values.tempat_lahir : null,
      tgl_lahir: tglLahir,
      kewarganegaraan: values.kewarganegaraan ? values.kewarganegaraan.value : null,
      agama: values.agama ? values.agama.value : null,
      no_kk: values.no_kk ? values.no_kk : null,
      kepala_keluarga: values.kepala_keluarga ? values.kepala_keluarga : null,
      no_akta_kelahiran: values.no_akta_kelahiran ? values.no_akta_kelahiran : null,
      no_kks: values.no_kks ? values.no_kks : null,
      no_pkh: values.no_pkh ? values.no_pkh : null,
      jml_saudara: values.jml_saudara ? values.jml_saudara : null,
      anak_ke: values.anak_ke ? values.anak_ke : null,
      no_telp: values.no_telp ? values.no_telp : null,
      email: values.email ? values.email : null,
      cita_cita: values.cita_cita ? values.cita_cita.value : null,
      hobi: values.hobi ? values.hobi.value : null,
      alamat: values.alamat ? values.alamat : null,
      kode_pos: values.kode_pos ? values.kode_pos : null,
      lat: values.lat ? values.lat : null,
      long: values.long ? values.long : null,
      status_tempat_tinggal: values.status_tempat_tinggal ? values.status_tempat_tinggal.value : null,
      transportasi: values.transportasi ? values.transportasi.value : null,

      biaya_dari: values.biaya_dari ? values.biaya_dari.value : null,
      kebutuhan_khusus: values.kebutuhan_khusus ? values.kebutuhan_khusus.value : null,
      kebutuhan_disabilitas: values.kebutuhan_disabilitas ? values.kebutuhan_disabilitas.value : null,
      berat_badan: values.berat_badan ? values.berat_badan : null,
      tinggi_badan: values.tinggi_badan ? values.tinggi_badan : null,
      lingkar_kepala: values.lingkar_kepala ? values.lingkar_kepala : null,
      pendidikan_pra_sekolah: values.pendidikan_pra_sekolah ? values.pendidikan_pra_sekolah.join(";") : null,
      imunisasi: values.imunisasi ? values.imunisasi.join(";") : null,
      foto: foto,

      jarak_tempuh: values.jarak_tempuh ? values.jarak_tempuh.value : null,
      waktu_tempuh: values.waktu_tempuh ? values.waktu_tempuh.value : null,

    }

    if (this.state.fileList.length > 0) {
      if (this.state.fileList[0].status === "error") {
        message.error('File image yang Anda upload tidak sesuai.!');
        return false
      }
    }

    this.setState({
      loadingBtn: true
    });

    _setAxios("siswa/data-diri/" + this.props.dataID, "PUT", params).then(resp => {
      if (resp.status === true) {
        _success('topRight', 'Success', 'Data berhasil disimpan')

        setTimeout(() => {
          window.location.reload(false);
        }, 1000);

      } else {
        this.setState({
          loadingBtn: false
        });
      }
    })
  };
  // SUBMIT END ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------


  render() {
    const action = this.props.action
    const { loading, dataDiri, errors, values, ddl, previewImage, fileList, visible } = this.state;

    const compDetail = <>
      <Row gutter={[16, 16]}>
        {
          !loading && (action.update) ?
            <Col xs={24} style={{ textAlign: "left" }}>
              <Tooltip placement="top" title={"Ubah data diri"}>
                <Button shape="circle" icon={<EditOutlined />} onClick={() => {
                  this.setState({
                    isUpdate: true
                  })
                }}></Button>
              </Tooltip>
            </Col>
            : ""
        }
        <Col xs={24} sm={12}>
          {
            !loading ?
              <List
                itemLayout="horizontal"
                dataSource={dataDiri.data2}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      title={item.title}
                      description={item.description}
                    />
                  </List.Item>
                )}
              />
              :
              <Skeleton />
          }
        </Col>

        <Col xs={24} sm={12}>
          {
            !loading ?
              <List
                itemLayout="horizontal"
                dataSource={dataDiri.data3}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      title={item.title}
                      description={item.description}
                    />
                  </List.Item>
                )}
              />
              :
              <Skeleton />
          }
        </Col>
      </Row>
    </>

    const form = <Row gutter={[24, 24]}>
      <Col xs={24} sm={16}>
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={6} className="form-left">
            <Row gutter={[24, 24]}>
              <Col xs={24}>
                <ImgCrop rotate>
                  <Upload
                    className="image-profile"
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture-card"
                    maxCount={1}
                    fileList={fileList}
                    onPreview={this.handlePreview}
                    onChange={this.handleChangeImage}
                    beforeUpload={this.beforeUploadImage}
                  >
                    {fileList.length >= 1 ? null : <div style={{ width: "100%" }}>
                      <PlusOutlined />
                      <div>Upload</div>
                    </div>}
                  </Upload>
                </ImgCrop>

                <Image
                  style={{ display: 'none' }}
                  src={noImage}
                  preview={{
                    visible,
                    src: previewImage,
                    onVisibleChange: value => {
                      this.setState({
                        visible: value
                      })
                    },
                  }}
                />
              </Col>
              <Col xs={24}>
                <TextField
                  fullWidth
                  label="NIK *"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="nik"
                  type="text"
                  onChange={this.handleChange}
                  value={values.nik || ''}
                  error={this.hasError('nik')}
                  helperText={
                    this.hasError('nik') ? errors.nik[0] : null
                  }
                  // InputProps={{
                  //   readOnly: true,
                  // }}
                />
              </Col>
              <Col xs={24}>
                <TextField
                  fullWidth
                  label="NISN *"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="nisn"
                  type="text"
                  onChange={this.handleChange}
                  value={values.nisn || ''}
                  error={this.hasError('nisn')}
                  helperText={
                    this.hasError('nisn') ? errors.nisn[0] : null
                  }
                  // InputProps={{
                  //   readOnly: true,
                  // }}
                />
              </Col>
              <Col xs={24}>
                <TextField
                  fullWidth
                  label="NIS"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="nis"
                  type="text"
                  onChange={this.handleChange}
                  value={values.nis || ''}
                  error={this.hasError('nis')}
                  helperText={
                    this.hasError('nis') ? errors.nis[0] : null
                  }
                  // InputProps={{
                  //   readOnly: true,
                  // }}
                />
              </Col>
              <Col xs={24}>
                <TextField
                  fullWidth
                  label="No. KIP"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="no_kip"
                  type="text"
                  onChange={this.handleChange}
                  value={values.no_kip || ''}
                  error={this.hasError('no_kip')}
                  helperText={
                    this.hasError('no_kip') ? errors.no_kip[0] : null
                  }
                  // InputProps={{
                  //   readOnly: true,
                  // }}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={18}>
            <Row gutter={[24, 24]}>
              <Col xs={24}>
                <Alert message="Informasi data siswa" type="info" showIcon />
              </Col>
              <Col xs={24}>
                <TextField
                  fullWidth
                  label="Nama Peserta Didik *"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="nama"
                  type="text"
                  onChange={this.handleChange}
                  value={values.nama || ''}
                  error={this.hasError('nama')}
                  helperText={
                    this.hasError('nama') ? errors.nama[0] : null
                  }
                  // InputProps={{
                  //   readOnly: true,
                  // }}
                />
              </Col>
              <Col xs={24} sm={24}>
                <FormLabel style={{ fontSize: "94%", lineHeight: "3em" }}>Jenis Kelamin</FormLabel>
                <Radio.Group style={{ width: '100%' }}
                  name="jenis_kelamin"
                  value={values.jenis_kelamin || ""}
                  onChange={this.handleChange}
                >
                  <Row gutter={[16, 16]}>
                    {genderOptions.map((row, i) => (
                      <Col xs={12} sm={8} md={6} key={i}>
                        <Radio
                          value={row.value}>{row.label}</Radio>
                      </Col>
                    ))}
                  </Row>
                </Radio.Group>
              </Col>
              <Col xs={24} sm={12}>
                <TextField
                  fullWidth
                  label="Tempat Lahir"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="tempat_lahir"
                  type="text"
                  onChange={this.handleChange}
                  value={values.tempat_lahir || ''}
                  error={this.hasError('tempat_lahir')}
                  helperText={
                    this.hasError('tempat_lahir') ? errors.tempat_lahir[0] : null
                  }
                  // InputProps={{
                  //   readOnly: true,
                  // }}
                />
              </Col>
              <Col xs={24} sm={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack spacing={3}>
                    <MobileDatePicker
                      label="Tanggal Lahir"
                      value={values.tgl_lahir || null}
                      onChange={(newValue) => {
                        this.handleChangeSetValue("tgl_lahir", newValue)
                      }}
                      readOnly={true}
                      renderInput={(params) => <TextField
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        helperText={
                          this.hasError('tgl_lahir') ? errors.tgl_lahir[0] : null
                        }
                        {...params} />}
                    />
                  </Stack>
                </LocalizationProvider>
              </Col>
              <Col xs={24} sm={12}>
                <Autocomplete
                  options={kewarganegaraanOptions}
                  value={values.kewarganegaraan || ''}
                  // readOnly={true}
                  onChange={(e, newValue) => {
                    this.handleChangeSetValue("kewarganegaraan", newValue ? newValue : null)
                  }}
                  renderInput={(params) => <TextField {...params}
                    fullWidth
                    label="Kewarganegaraan"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="kewarganegaraan"
                    type="text"
                    error={this.hasError('kewarganegaraan')}
                    helperText={
                      this.hasError('kewarganegaraan') ? errors.kewarganegaraan[0] : null
                    }
                  />}
                />
              </Col>
              <Col xs={24} sm={12}>
                <Autocomplete
                  options={agamaOptions}
                  value={values.agama || ''}
                  // readOnly={true}
                  onChange={(e, newValue) => {
                    this.handleChangeSetValue("agama", newValue ? newValue : null)
                  }}

                  renderInput={(params) => <TextField {...params}
                    fullWidth
                    label="Agama"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="agama"
                    type="text"
                    error={this.hasError('agama')}
                    helperText={
                      this.hasError('agama') ? errors.agama[0] : null
                    }
                  />}
                />
              </Col>
              <Col xs={24}>
                <Divider />
              </Col>
              <Col xs={24}>
                <TextField
                  fullWidth
                  label="No. KK"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="no_kk"
                  type="text"
                  onChange={this.handleChange}
                  value={values.no_kk || ''}
                  error={this.hasError('no_kk')}
                  helperText={
                    this.hasError('no_kk') ? errors.no_kk[0] : null
                  }
                />
              </Col>
              <Col xs={24}>
                <TextField
                  fullWidth
                  label="Nama Kepala Keluarga"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="kepala_keluarga"
                  type="text"
                  onChange={this.handleChange}
                  value={values.kepala_keluarga || ''}
                  error={this.hasError('kepala_keluarga')}
                  helperText={
                    this.hasError('kepala_keluarga') ? errors.kepala_keluarga[0] : null
                  }
                />
              </Col>
              <Col xs={24} sm={8}>
                <TextField
                  fullWidth
                  label="No. Akta Kelahiran"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="no_akta_kelahiran"
                  type="text"
                  onChange={this.handleChange}
                  value={values.no_akta_kelahiran || ''}
                  error={this.hasError('no_akta_kelahiran')}
                  helperText={
                    this.hasError('no_akta_kelahiran') ? errors.no_akta_kelahiran[0] : null
                  }
                />
              </Col>
              <Col xs={24} sm={8}>
                <TextField
                  fullWidth
                  label="No. KKS"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="no_kks"
                  type="text"
                  onChange={this.handleChange}
                  value={values.no_kks || ''}
                  error={this.hasError('no_kks')}
                  helperText={
                    this.hasError('no_kks') ? errors.no_kks[0] : null
                  }
                />
              </Col>
              <Col xs={24} sm={8}>
                <TextField
                  fullWidth
                  label="No. PKH"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="no_pkh"
                  type="text"
                  onChange={this.handleChange}
                  value={values.no_pkh || ''}
                  error={this.hasError('no_pkh')}
                  helperText={
                    this.hasError('no_pkh') ? errors.no_pkh[0] : null
                  }
                />
              </Col>

              <Col xs={24} sm={12}>
                <TextField
                  fullWidth
                  label="Jumlah Saudara"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="jml_saudara"
                  type="text"
                  onChange={this.handleChange}
                  value={values.jml_saudara || ''}
                  error={this.hasError('jml_saudara')}
                  helperText={
                    this.hasError('jml_saudara') ? errors.jml_saudara[0] : null
                  }
                />
              </Col>
              <Col xs={24} sm={12}>
                <TextField
                  fullWidth
                  label="Anak ke"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="anak_ke"
                  type="text"
                  onChange={this.handleChange}
                  value={values.anak_ke || ''}
                  error={this.hasError('anak_ke')}
                  helperText={
                    this.hasError('anak_ke') ? errors.anak_ke[0] : null
                  }
                />
              </Col>

              <Divider />

              <Col xs={24}>
                <Row gutter={[24, 24]}>
                  <Col xs={24} sm={24}>
                    <Autocomplete
                      options={statusTempatTinggalOptions}
                      value={values.status_tempat_tinggal || ""}
                      onChange={(e, newValue) => {
                        this.handleChangeSetValue("status_tempat_tinggal", newValue)
                      }}
                      renderInput={(params) => <TextField {...params}
                        fullWidth
                        label="Status Tempat Tinggal"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="status_tempat_tinggal"
                        type="text"
                        error={this.hasError('status_tempat_tinggal')}
                        helperText={
                          this.hasError('status_tempat_tinggal') ? errors.status_tempat_tinggal[0] : null
                        }
                      />}
                    />
                  </Col>
                  <Col xs={24} sm={24}>
                    <Autocomplete
                      options={transportasiOptions}
                      value={values.transportasi || ''}
                      onChange={(e, newValue) => {
                        this.handleChangeSetValue("transportasi", newValue)
                      }}
                      renderInput={(params) => <TextField {...params}
                        fullWidth
                        label="Transportasi ke Sekolah"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="transportasi"
                        type="text"
                        error={this.hasError('transportasi')}
                        helperText={
                          this.hasError('transportasi') ? errors.transportasi[0] : null
                        }
                      />}
                    />
                  </Col>
                  <Col xs={24} sm={12}>
                    <Autocomplete
                      options={jarakTempuhOptions}
                      value={values.jarak_tempuh || ''}
                      onChange={(e, newValue) => {
                        this.handleChangeSetValue("jarak_tempuh", newValue)
                      }}
                      renderInput={(params) => <TextField {...params}
                        fullWidth
                        label="Jarak Tempuh"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="jarak_tempuh"
                        type="text"
                        error={this.hasError('jarak_tempuh')}
                        helperText={
                          this.hasError('jarak_tempuh') ? errors.jarak_tempuh[0] : null
                        }
                      />}
                    />
                  </Col>
                  <Col xs={24} sm={12}>
                    <Autocomplete
                      options={waktuTempuhOptions}
                      value={values.waktu_tempuh || ''}
                      onChange={(e, newValue) => {
                        this.handleChangeSetValue("waktu_tempuh", newValue)
                      }}
                      renderInput={(params) => <TextField {...params}
                        fullWidth
                        label="Waktu Tempuh"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="waktu_tempuh"
                        type="text"
                        error={this.hasError('waktu_tempuh')}
                        helperText={
                          this.hasError('waktu_tempuh') ? errors.waktu_tempuh[0] : null
                        }
                      />}
                    />
                  </Col>

                  <Col xs={24} sm={24}>
                    <Autocomplete
                      options={biayaDariOptions}
                      value={values.biaya_dari || ''}
                      onChange={(e, newValue) => {
                        this.handleChangeSetValue("biaya_dari", newValue)
                      }}
                      renderInput={(params) => <TextField {...params}
                        fullWidth
                        label="Yang Membiayai Sekolah"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="biaya_dari"
                        type="text"
                        error={this.hasError('biaya_dari')}
                        helperText={
                          this.hasError('biaya_dari') ? errors.biaya_dari[0] : null
                        }
                      />}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={24}>
                <Row gutter={[24, 24]}>
                  <Col xs={24}>
                    <Autocomplete
                      options={kebutuhanKhususOptions}
                      value={values.kebutuhan_khusus || ''}
                      onChange={(e, newValue) => {
                        this.handleChangeSetValue("kebutuhan_khusus", newValue)
                      }}
                      renderInput={(params) => <TextField {...params}
                        fullWidth
                        label="Kebutuhan Khusus"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="kebutuhan_khusus"
                        type="text"
                        error={this.hasError('kebutuhan_khusus')}
                        helperText={
                          this.hasError('kebutuhan_khusus') ? errors.kebutuhan_khusus[0] : null
                        }
                      />}
                    />
                  </Col>
                  <Col xs={24}>
                    <Autocomplete
                      options={kebutuhanDisabilitasOptions}
                      value={values.kebutuhan_disabilitas || ''}
                      onChange={(e, newValue) => {
                        this.handleChangeSetValue("kebutuhan_disabilitas", newValue)
                      }}
                      renderInput={(params) => <TextField {...params}
                        fullWidth
                        label="Kebutuhan Disablilitas"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="kebutuhan_disabilitas"
                        type="text"
                        error={this.hasError('kebutuhan_disabilitas')}
                        helperText={
                          this.hasError('kebutuhan_disabilitas') ? errors.kebutuhan_disabilitas[0] : null
                        }
                      />}
                    />
                  </Col>
                  <Col xs={24} sm={8}>
                    <TextField
                      fullWidth
                      label="Berat Badan (kg)"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="berat_badan"
                      type="number"
                      onChange={this.handleChange}
                      value={values.berat_badan || ''}
                      error={this.hasError('berat_badan')}
                      helperText={
                        this.hasError('berat_badan') ? errors.berat_badan[0] : null
                      }
                    />
                  </Col>
                  <Col xs={24} sm={8}>
                    <TextField
                      fullWidth
                      label="Tinggi Badan (cm)"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="tinggi_badan"
                      type="number"
                      onChange={this.handleChange}
                      value={values.tinggi_badan || ''}
                      error={this.hasError('tinggi_badan')}
                      helperText={
                        this.hasError('tinggi_badan') ? errors.tinggi_badan[0] : null
                      }
                    />
                  </Col>
                  <Col xs={24} sm={8}>
                    <TextField
                      fullWidth
                      label="Lingkar Kepala (cm)"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="lingkar_kepala"
                      type="number"
                      onChange={this.handleChange}
                      value={values.lingkar_kepala || ''}
                      error={this.hasError('lingkar_kepala')}
                      helperText={
                        this.hasError('lingkar_kepala') ? errors.lingkar_kepala[0] : null
                      }
                    />
                  </Col>
                  <Col xs={24} sm={24}>
                    <FormLabel style={{ fontSize: "94%", lineHeight: "3em" }}>Pendidikan Pra Sekolah</FormLabel>
                    <Checkbox.Group style={{ width: '100%' }}
                      name="pendidikan_pra_sekolah"
                      defaultValue={values.pendidikan_pra_sekolah}
                      onChange={(newValue) => {
                        this.handleChangeSetValue("pendidikan_pra_sekolah", newValue)
                      }}>
                      <Row gutter={[16, 16]}>
                        {praSchoolOptions.map((row, i) => (
                          <Col span={12} key={i}>
                            <Checkbox
                              value={row.value}
                            // checked={values.pendidikan_pra_sekolah === row.value ? "checked" : ""}
                            >{row.label}</Checkbox>
                          </Col>
                        ))}
                      </Row>
                    </Checkbox.Group>

                  </Col>
                  <Col xs={24} sm={24}>
                    <FormLabel style={{ fontSize: "94%", lineHeight: "3em" }}>Imunisasi</FormLabel>
                    <Checkbox.Group style={{ width: '100%' }}
                      name="imunisasi"
                      defaultValue={values.imunisasi}
                      onChange={(newValue) => {
                        this.handleChangeSetValue("imunisasi", newValue)
                      }}>
                      <Row gutter={[16, 16]}>
                        {imunisasiOptions.map((row, i) => (
                          <Col span={12} key={i}>
                            <Checkbox
                              name="imunisasi"
                              value={row.value}
                              onChange={this.handleChange}
                              checked={values.imunisasi === row.value ? "checked" : ""}

                            >{row.label}</Checkbox>
                          </Col>
                        ))}
                      </Row>
                    </Checkbox.Group>
                  </Col>
                  <Col xs={24} sm={24}>
                    <Autocomplete
                      options={citacitaOptions}
                      value={values.cita_cita || null}
                      onChange={(e, newValue) => {
                        this.handleChangeSetValue("cita_cita", newValue)
                      }}

                      renderInput={(params) => <TextField {...params}
                        fullWidth
                        label="Cita-Cita"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="cita_cita"
                        type="text"
                        error={this.hasError('cita_cita')}
                        helperText={
                          this.hasError('cita_cita') ? errors.cita_cita[0] : null
                        }
                      />}
                    />
                  </Col>
                  <Col xs={24} sm={24}>
                    <Autocomplete
                      options={hobiOptions}
                      value={values.hobi || null}
                      onChange={(e, newValue) => {
                        this.handleChangeSetValue("hobi", newValue)
                      }}

                      renderInput={(params) => <TextField {...params}
                        fullWidth
                        label="Hobi"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="hobi"
                        type="text"
                        error={this.hasError('hobi')}
                        helperText={
                          this.hasError('hobi') ? errors.hobi[0] : null
                        }
                      />}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={8}>
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={12}>
            <TextField
              fullWidth
              label="No. Telepon"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              name="no_telp"
              type="text"
              onChange={this.handleChange}
              value={values.no_telp || ''}
              error={this.hasError('no_telp')}
              helperText={
                this.hasError('no_telp') ? errors.no_telp[0] : null
              }
            />
          </Col>
          <Col xs={24} sm={12}>
            <TextField
              fullWidth
              label="E-Mail"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              name="email"
              type="text"
              onChange={this.handleChange}
              value={values.email || ''}
              error={this.hasError('email')}
              helperText={
                this.hasError('email') ? errors.email[0] : null
              }
            />
          </Col>
          <Col xs={24} sm={24}>
            <Autocomplete
              options={ddl.regions}
              value={values.region || null}
              getOptionLabel={(option) =>
                option.provinsi_nama + ", " + option.kabkot_nama + ", " + option.kecamatan_nama
              }
              onChange={(e, newValue) => {
                this.handleChangeSetValue("region", newValue)
              }}
              renderInput={(params) => <TextField {...params}
                fullWidth
                label="Provinsi, Kota & Kecamatan"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                name="region"
                type="text"
                onChange={e => {
                  const { name, value } = e.target;
                  this.getRegionDDL(name, value)
                }}
                error={this.hasError('region')}
                helperText={
                  this.hasError('region') ? errors.region[0] : null
                }
              />}
            />
          </Col>
          <Col xs={24} sm={12}>
            <Autocomplete
              options={ddl.villages}
              value={values.village || null}
              getOptionLabel={(option) =>
                option.nama
              }
              onChange={(e, newValue) => {
                this.handleChangeSetValue("village", newValue)
              }}
              renderInput={(params) => <TextField {...params}
                fullWidth
                label="Kelurahan / Desa"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                name="village"
                type="text"
                error={this.hasError('village')}
                helperText={
                  this.hasError('village') ? errors.village[0] : null
                }
              />}
            />
          </Col>
          <Col xs={24} sm={12}>
            <TextField
              fullWidth
              label="Kode Pos"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              name="kode_pos"
              type="text"
              onChange={this.handleChange}
              value={values.kode_pos || ''}
              error={this.hasError('kode_pos')}
              helperText={
                this.hasError('kode_pos') ? errors.kode_pos[0] : null
              }
            />
          </Col>
          <Col xs={24} sm={24}>
            <TextField
              multiline
              fullWidth
              label="Alamat"
              // size="small"
              minRows={3}
              InputLabelProps={{
                shrink: true,
              }}
              name="alamat"
              type="text"
              onChange={this.handleChange}
              value={values.alamat || ''}
              error={this.hasError('alamat')}
              helperText={
                this.hasError('alamat') ? errors.alamat[0] : null
              }
            />
          </Col>
          <Col xs={24}>
            <Divider orientation="left">Koordinat</Divider>
          </Col>
          <Col xs={24} sm={12}>
            <TextField
              fullWidth
              label="Latitude"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              name="lat"
              type="text"
              onChange={this.handleChange}
              value={values.lat || ''}
              error={this.hasError('lat')}
              helperText={
                this.hasError('lat') ? errors.lat[0] : null
              }
            />
          </Col>
          <Col xs={24} sm={12}>
            <TextField
              fullWidth
              label="Longitude"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              name="long"
              type="text"
              onChange={this.handleChange}
              value={values.long || ''}
              error={this.hasError('long')}
              helperText={
                this.hasError('long') ? errors.long[0] : null
              }
            />
          </Col>
          <Col xs={24}>
            <iframe title={"unik"}
              src={"https://maps.google.com/maps?q=" + (values.lat ? values.lat : -7.052652) + "," + (values.long ? values.long : 112.5685274) + "&hl=id&z=14&output=embed"}
              style={{ width: "100%", border: 0 }}
              height="300" loading="lazy">
            </iframe>
          </Col>

        </Row>
      </Col>
      <Col xs={24} style={{ textAlign: "left" }}>
        <Button style={{ margin: 0 }} loading={this.state.loadingBtn} onClick={() => {
          this.setState({
            isUpdate: false
          })
        }}>
          Batal
        </Button>
        <Button style={{ margin: '0 0 0 8px' }} type="primary" htmlType="submit" icon={<SaveOutlined />} loading={this.state.loadingBtn} onClick={this.onSubmitDataDiri}>
          Simpan
        </Button>
      </Col>
    </Row>

    return (
      <>
        {this.state.isUpdate ? form : compDetail}
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(DataDiri);