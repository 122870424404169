import React from 'react';
import { Select, Form, Input, Button, Row, Col, Modal, Checkbox } from 'antd';
import validate from 'validate.js';
import { _success, _error, _setAxios, _getToken } from '../../lib/Helper';
import { LoginOutlined } from '@ant-design/icons';
import Config from "../../Config";
import "./Login.css"

const { Option } = Select;
const Cryptr = require('cryptr');
const cryptr = new Cryptr(Config.secret_key);

export default class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loadingBtn: false,
            tahunAjaran: {
                visible: false,
                loading: false,
                ddl: [],
                data: {}
            },
            copiright: "Madrasah Digital MAN 1 Gresik @" + new Date().getFullYear()
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const encryptedRemember = localStorage.getItem('persist:remember');
        if (encryptedRemember !== null && encryptedRemember !== "") {
            // console.log("encryptedRemember", encryptedRemember)
            const values = cryptr.decrypt(encryptedRemember);
            this.setState({
                values: {
                    ...JSON.parse(values)
                }
            })
        }
    }

    resetForm() {
        this.setState({
            values: {},
            isValid: false,
            errors: {}
        })
    }

    handleChange = (e, row) => {
        const { name, value } = e.target;
        this.setForm(name, value)
    };

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    setForm = (name, value) => {
        const { values } = this.state;
        const errors = validate(values, schema);

        values[name] = value;
        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    handleSubmit = e => {
        if (this.state.submitDisabled === true) {
            return
        }
        e.preventDefault();
        const { values } = this.state;
        const errors = validate(values, schema);
        if (!errors) {
            this.setState({
                loadingBtn: true,
            })
            _setAxios("login", "POST", values, "data").then(resp => {
                if (resp.status === true) {
                    var token = resp.data.token_type + " " + resp.data.access_token
                    localStorage.setItem('persist:auth', token);
                    localStorage.setItem('persist:remember', "");
                    if (values.remember) {
                        localStorage.setItem('persist:remember', cryptr.encrypt(JSON.stringify(values)));
                    }

                    if(!this.checkAuth()){
                    }else{
                        _success('topRight', 'Success', resp.data.message)
                    }
                } else {
                    this.setState({
                        loadingBtn: false,
                    })
                }
            })
        } else {
            if (errors.email) {
                _error('topRight', 'Email', errors.email[0])
            } else if (errors.password) {
                _error('topRight', 'Password', errors.password[0])
            }

            this.setState({
                submitIcon: <LoginOutlined />,
            })
        }
    };

    checkAuth() {
        if (!_getToken()) {
            _error('topRight', 'Access', 'Gagal mendapatkan akses')
        } else {
            _setAxios("privilege/access", "GET").then(resp => {
                if (resp.status === true) {
                    const profile = resp.data.data.profile
                    const tahunAjaran = resp.data.data.tahun_ajaran
                    const levelSiswa = ["siswa", "wali siswa", "alumni"];

                    tahunAjaran.id = tahunAjaran.id_tahun_ajaran
                    localStorage.setItem('persist:tahun_ajaran', JSON.stringify(tahunAjaran));

                    if (levelSiswa.includes(profile.roles.toLowerCase())) {
                        window.location.href = Config.base_url
                        return true
                    } else {
                        _error('topRight', 'Access', 'Anda tidak memiliki akses ke aplikasi ini')
                        this.setState({
                            loadingBtn: false,
                        })
                        localStorage.clear();
                        return false
                    }
                } else {
                    this.setState({
                        loadingBtn: false,
                    })
                    _error('topRight', 'Access', 'Gagal mendapatkan akses')
                }
            })

        }
    }

    getTahunAjaranDDL = () => {
        _setAxios("tahun-ajaran", "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    tahunAjaran: {
                        ...this.state.tahunAjaran,
                        visible: true,
                        ddl: resp.data.data,
                    }
                })
            }
        })
    }

    setValueInput = (value) => {
        let tahunAjaran = {}
        this.state.tahunAjaran.ddl.forEach(row => {
            if (row.id === value) {
                tahunAjaran = row
                return
            }
        });

        this.setState({
            tahunAjaran: {
                ...this.state.tahunAjaran,
                value: tahunAjaran
            }
        })
    };


    filterTahunAjaranSubmit() {
        localStorage.setItem('persist:tahun_ajaran', JSON.stringify(this.state.tahunAjaran.value));

        this.setState({
            tahunAjaran: {
                ...this.state.tahunAjaran,
                loading: true,
            }
        })

        this.setRedirect()
    }

    setRedirect() {
        window.location.href = Config.base_url
    }

    render() {

        const { values, tahunAjaran } = this.state

        const modalFilterTahunAjaran = <Modal
            title="Filter Tahun Ajaran"
            width={300}
            visible={tahunAjaran.visible}
            onCancel={false}
            closeIcon=" "
            footer={<>
                <Button type="primary" htmlType="submit" onClick={this.filterTahunAjaranSubmit.bind(this)} loading={tahunAjaran.loading}>Lanjutkan</Button>
            </>}
        >
            <form autoComplete="off" onSubmit={this.filterTahunAjaranSubmit.bind(this)}>
                <Select
                    showSearch
                    allowClear
                    name="tahun_ajaran_id"
                    placeholder="Tahun Ajaran"
                    optionFilterProp="children"
                    onChange={(e, newValue) => {
                        this.setValueInput(newValue ? newValue.value : null)
                    }}
                    style={{ width: '100%' }}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {tahunAjaran.ddl.map((row, i) => <Option key={i} value={row.id}>{row.thn_awal} / {row.thn_akhir} - {row.tipe_semester}</Option>)}
                </Select>
            </form>
        </Modal>


        return (
            <>
                <Row style={{
                    height: "100%"
                }}>
                    <Col xs={24} sm={12} className="login-background"></Col>
                    <Col xs={24} sm={12}>
                        <div className='section-login'>
                            <div className='div-login'>
                                <div className="login-logo"></div>
                                <div className="login-title">Assalamu'alaikum</div>
                                <div className="login-subtitle">Masukkan email dan password yang terdaftar</div>
                                <div className="login-form">
                                    <Form
                                        layout="vertical"
                                        style={{ width: "100%" }}
                                        onFinish={this.handleSubmit}
                                        autoComplete="on"
                                        className='login'
                                        onSubmit={this.handleSubmit}
                                    >
                                        <Form.Item label="E-Mail">
                                            <Input placeholder="Contoh: john.doe@man1gresik.sch.id"
                                                onChange={this.handleChange}
                                                type="text"
                                                name="email"
                                                value={values.email || ''}
                                                validateStatus="error"
                                                help="Should be combination of numbers & alphabets"
                                            />
                                        </Form.Item>
                                        <Form.Item label="Password">
                                            <Input.Password placeholder="Masukkan password dengan benar"
                                                onChange={this.handleChange}
                                                type="text"
                                                name="password"
                                                value={values.password || ''}
                                                validateStatus="error"
                                                help="Should be combination of numbers & alphabets"
                                            />
                                        </Form.Item>
                                        <Form.Item label="">
                                            <Checkbox style={{ width: "100%" }}
                                                name="remember"
                                                onChange={(e) => {
                                                    this.setForm("remember", e.target.checked)
                                                }}
                                                value={values.remember || ''}
                                            >Ingat Saya</Checkbox>
                                        </Form.Item>
                                        <Form.Item>
                                            <Button htmlType="submit" className='login-btn' onClick={this.handleSubmit} loading={this.state.loadingBtn}>MASUK</Button>
                                        </Form.Item>
                                    </Form>

                                </div>
                                <div className="login-footer">{this.state.copiright}</div>
                            </div>
                        </div>
                    </Col>
                </Row>

                {modalFilterTahunAjaran}
            </>
        )
    }
}

const schema = {
    email: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
        length: {
            maximum: 255

        }
    },
    password: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
        length: {
            maximum: 255,
        }
    }
};
