import React from 'react';
import validate from 'validate.js';
import { Button, Row, Col, Image, Radio, Upload } from 'antd';
import { PlusOutlined, SaveOutlined } from '@ant-design/icons';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import Autocomplete from '@mui/material/Autocomplete';
import ImgCrop from 'antd-img-crop';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import Stack from '@mui/material/Stack';

import { _validationFormMsg, _setAxios, _beforeUploadImage, _getBase64, _getFile } from '../../../lib/Helper';
import { genderOptions } from '../../../data/options';
import { noImage } from '../../../data/image';
import PreloadContent from '../../../components/preload/PreloadContent'
import moment from 'moment';

const schema = {
  nama: {
    presence: { allowEmpty: false, message: 'harus diisi' },
    length: {
      maximum: 200
    },
    format: {
      pattern: /^$|[a-zA-Z\s]+/,
      message: function () {
        return validate.format("^Nama peserta didik harus berupa huruf");
      }
    },
  },
  nik: {
    presence: { allowEmpty: false, message: 'harus diisi' },
    format: {
      pattern: /^$|[0-9]+/,
      message: function () {
        return validate.format("^Nik harus berupa agka");
      }
    },
    length: {
      minimum: 16,
      maximum: 16,
      message: "jumlah karakter harus 16 digit"
    },
  },
  tgl_lahir: {
    presence: { allowEmpty: false, message: 'harus diisi' },
  },
  no_telp: {
    length: {
      maximum: 14,
      message: "terlalu panjang (maksimal 14 karakter)"
    },
    format: {
      pattern: /^$|(\+62 ((\d{3}([ -]\d{3,})([- ]\d{4,})?)|(\d+)))|(\(\d+\) \d+)|\d{3}( \d+)+|(\d+[ -]\d+)|\d+/,
      message: function (value) {
        return validate.format("^No telp %{phone} tidak valid", {
          phone: value
        });
      }
    },
  },
  email: {
    presence: { allowEmpty: false, message: 'harus diisi' },
    format: {
      pattern: /^$|\S+@\S+\.\S+/,
      message: function (value) {
        return validate.format("^Email %{email} tidak valid", {
          email: value
        });
      }
    },
  },
  kode_pos: {
    length: {
      maximum: 6,
      message: "terlalu panjang (maksimal 6 karakter)"
    },
    format: {
      pattern: /^$|[0-9]+/,
      message: function () {
        return validate.format("^Kode pos harus berupa agka");
      }
    },
  },
};

class DataDiriForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isUpdate: false,
      loading: false,
      loadingBtn: false,
      visible: false,
      values: {},
      errors: {},
      fileList: [],
      previewImage: null,
      ddl: {
        regions: [],
        villages: [],
        regions_pekerjaan: [],
        villages_pekerjaan: [],
      },
    }
  }

  componentDidMount() {
    this.getDataDiriByID(this.props.dataID)
  }
  handleChange = (e) => {
    const { values } = this.state;
    const { name, value } = e.target;

    this.setForm(values, name, value)
  };

  handleChangeSetValue = (name, value) => {
    const { values } = this.state;
    this.setForm(values, name, value)
  };

  setForm = (values, name, value) => {
    values[name] = value;
    const errors = validate(values, schema);
    this.setState({
      values: values,
      errors: errors || {},
    });
  }

  hasError = field => {
    return this.state.errors[field] ? true : false;
  }

  getDataDiriByID = (id) => {
    this.setState({
      loading: true,
    })
    _setAxios("alumni/data-diri/" + id, "GET").then(resp => {
      if (resp.status === true) {
        const data = resp.data.data

        this.setState({
          loading: false,
          fileList: data.foto ? [{
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: data.foto ? data.main_path + data.foto : noImage,
          }] : [],
          values: {
            ...data,
            region: data.kecamatan_id ? {
              kecamatan_id: data.kecamatan_id,
              kecamatan_nama: data.kecamatan_nama,
              kabkot_id: data.kabkot_id,
              kabkot_nama: data.kabkot_nama,
              provinsi_id: data.provinsi_id,
              provinsi_nama: data.provinsi_nama
            } : null,
            village: data.kelurahan_id ? {
              id: data.kelurahan_id,
              nama: data.kelurahan_nama
            } : null
          }
        })
      } else {
        this.setState({
          loading: false,
        })
      }
    })
  }

  getRegionDDL = (search = "") => {
    _setAxios("region", "POST", {
      pagination: {
        current: 1,
        pageSize: 10
      },
      search: search
    }).then(resp => {
      if (resp.status === true) {
        this.setState({
          ddl: {
            ...this.state.ddl,
            regions: resp.data.list
          }
        });
      }
    })
  }

  getVillageDDL = (subDistrictID) => {
    _setAxios("region/" + subDistrictID, "POST", {
      pagination: {
        current: 1,
        pageSize: 30
      },
    }).then(resp => {
      if (resp.status === true) {
        this.setState({
          ddl: {
            ...this.state.ddl,
            villages: resp.data.data
          }
        });
      }
    })
  }

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await _getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      visible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  handleChangeImage = ({ fileList }) => {
    if (this.state.validateImage === true) {
      fileList.forEach((row, i) => {
        fileList[i].status = "done"
      });
    }

    var foto = this.state.values.foto
    if (fileList.length > 0) {
      foto = fileList[0].thumbUrl
    }
    this.setState({
      fileList,
      values: {
        ...this.state.values,
        foto: foto
      },
    })
  };

  beforeUploadImage = (file) => {
    const validate = _beforeUploadImage(file)
    if (validate === false) {
      this.setState({ validateImage: false })
    } else {
      this.setState({ validateImage: true })
    }
    return true
  };

  onSubmit = async () => {
    this.setState({
      loadingBtn: true
    })

    const { values } = this.state;
    const errors = validate(values, schema);
    if (errors) {
      return _validationFormMsg(errors)
    }
    let tglLahir = moment(values.tgl_lahir).format("DD-MM-YYYY")

    _setAxios("alumni/data-diri/" + this.props.dataID, "PUT", {
      foto: await _getFile(this.state.fileList),
      nik: values.nik,
      nama: values.nama,
      jenis_kelamin: values.jenis_kelamin,
      tempat_lahir: values.tempat_lahir,
      tgl_lahir: tglLahir,
      provinsi_id: values.provinsi_id,
      kabkot_id: values.kabkot_id,
      kecamatan_id: values.kecamatan_id,
      kelurahan_id: values.village.id,
      alamat: values.alamat,
      kode_pos: values.kode_pos,
      no_telp: values.no_telp,
      email: values.email,
      tahun_masuk: values.tahun_masuk,
      tahun_lulus: values.tahun_lulus
    }).then(resp => {
      if (resp.status === true) {
        this.props.onCancel()
      } else {
        this.setState({
          loadingBtn: false
        })
      }
    })
  }

  render() {
    const { action } = this.props
    const { values, errors, ddl, loading, loadingBtn, fileList, visible, previewImage } = this.state
    if (loading) {
      return <PreloadContent />
    }
    return (
      <Row gutter={[24, 24]}>
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={5} className="form-left">
            <Row gutter={[24, 24]}>
              <Col xs={24}>
                <ImgCrop rotate>
                  <Upload
                    className="image-profile"
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture-card"
                    maxCount={1}
                    fileList={fileList}
                    onPreview={this.handlePreview}
                    onChange={this.handleChangeImage}
                    beforeUpload={this.beforeUploadImage}
                  >
                    {fileList.length >= 1 ? null : <div style={{ width: "100%" }}>
                      <PlusOutlined />
                      <div>Upload</div>
                    </div>}
                  </Upload>
                </ImgCrop>

                <Image
                  style={{ display: 'none' }}
                  src={noImage}
                  preview={{
                    visible,
                    src: previewImage,
                    onVisibleChange: value => {
                      this.setState({
                        visible: value
                      })
                    },
                  }}
                />
              </Col>
              <Col xs={24}>
                <TextField
                  fullWidth
                  label="NIK *"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="nik"
                  type="text"
                  onChange={this.handleChange}
                  value={values.nik || ''}
                  error={this.hasError('nik')}
                  helperText={
                    this.hasError('nik') ? errors.nik[0] : null
                  }
                  InputProps={{
                    readOnly: action.update ? false : true,
                  }}
                />
              </Col>
              <Col xs={24}>
                <TextField
                  fullWidth
                  label="No.KTA *"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="no_kta"
                  type="text"
                  onChange={this.handleChange}
                  value={values.no_kta || ''}
                  error={this.hasError('no_kta')}
                  helperText={
                    this.hasError('no_kta') ? errors.no_kta[0] : null
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={19}>
            <Row gutter={[24, 24]}>
              <Col xs={24}>
                <TextField
                  fullWidth
                  label="Nama Alumni *"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="nama"
                  type="text"
                  onChange={this.handleChange}
                  value={values.nama || ''}
                  error={this.hasError('nama')}
                  helperText={
                    this.hasError('nama') ? errors.nama[0] : null
                  }
                  InputProps={{
                    readOnly: action.update ? false : true,
                  }}
                />
              </Col>
              <Col xs={24} sm={24}>
                <FormLabel style={{ fontSize: "94%", lineHeight: "3em" }}>Jenis Kelamin</FormLabel>
                <Radio.Group style={{ width: '100%' }}
                  name="jenis_kelamin"
                  value={values.jenis_kelamin || ""}
                  onChange={this.handleChange}
                >
                  <Row gutter={[16, 16]}>
                    {genderOptions.map((row, i) => (
                      <Col xs={12} sm={8} md={6} key={i}>
                        <Radio
                          disabled={action.update ? false : true}
                          value={row.value}>{row.label}</Radio>
                      </Col>
                    ))}
                  </Row>
                </Radio.Group>
              </Col>
              <Col xs={24} sm={12}>
                <TextField
                  fullWidth
                  label="Tempat Lahir"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="tempat_lahir"
                  type="text"
                  onChange={this.handleChange}
                  value={values.tempat_lahir || ''}
                  error={this.hasError('tempat_lahir')}
                  helperText={
                    this.hasError('tempat_lahir') ? errors.tempat_lahir[0] : null
                  }
                  InputProps={{
                    readOnly: action.update ? false : true,
                  }}
                />
              </Col>
              <Col xs={24} sm={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack spacing={3}>
                    <MobileDatePicker
                      label="Tanggal Lahir"
                      value={values.tgl_lahir || null}
                      onChange={(newValue) => {
                        this.handleChangeSetValue("tgl_lahir", newValue)
                      }}
                      readOnly={action.update ? false : true}
                      renderInput={(params) => <TextField
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        helperText={
                          this.hasError('tgl_lahir') ? errors.tgl_lahir[0] : null
                        }
                        {...params} />}
                    />
                  </Stack>
                </LocalizationProvider>
              </Col>
              <Col xs={24} sm={12}>
                <TextField
                  fullWidth
                  label="No. Telepon"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="no_telp"
                  type="text"
                  onChange={this.handleChange}
                  value={values.no_telp || ''}
                  error={this.hasError('no_telp')}
                  helperText={
                    this.hasError('no_telp') ? errors.no_telp[0] : null
                  }
                />
              </Col>
              <Col xs={24} sm={12}>
                <TextField
                  fullWidth
                  label="E-Mail"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="email"
                  type="text"
                  onChange={this.handleChange}
                  value={values.email || ''}
                  error={this.hasError('email')}
                  helperText={
                    this.hasError('email') ? errors.email[0] : null
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Col>
              <Col xs={24} sm={24}>
                <Autocomplete
                  options={ddl.regions}
                  value={values.region || null}
                  getOptionLabel={(option) =>
                    option.provinsi_nama + ", " + option.kabkot_nama + ", " + option.kecamatan_nama
                  }
                  onChange={(e, newValue) => {
                    this.handleChangeSetValue("region", newValue)
                    this.getVillageDDL(newValue.kecamatan_id)
                  }}
                  renderInput={(params) => <TextField {...params}
                    fullWidth
                    label="Provinsi, Kota & Kecamatan"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="region"
                    type="text"
                    onChange={e => {
                      const { value } = e.target;
                      this.getRegionDDL(value)
                    }}
                    error={this.hasError('region')}
                    helperText={
                      this.hasError('region') ? errors.region[0] : null
                    }
                  />}
                />
              </Col>
              <Col xs={24} sm={12}>
                <Autocomplete
                  options={ddl.villages}
                  value={values.village || null}
                  getOptionLabel={(option) =>
                    option.nama
                  }
                  onChange={(e, newValue) => {
                    this.handleChangeSetValue("village", newValue)
                  }}
                  renderInput={(params) => <TextField {...params}
                    fullWidth
                    label="Kelurahan / Desa"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="village"
                    type="text"
                    error={this.hasError('village')}
                    helperText={
                      this.hasError('village') ? errors.village[0] : null
                    }
                  />}
                />
              </Col>
              <Col xs={24} sm={12}>
                <TextField
                  fullWidth
                  label="Kode Pos"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="kode_pos"
                  type="text"
                  onChange={this.handleChange}
                  value={values.kode_pos || ''}
                  error={this.hasError('kode_pos')}
                  helperText={
                    this.hasError('kode_pos') ? errors.kode_pos[0] : null
                  }
                />
              </Col>
              <Col xs={24} sm={24}>
                <TextField
                  multiline
                  fullWidth
                  label="Alamat"
                  // size="small"
                  minRows={3}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="alamat"
                  type="text"
                  onChange={this.handleChange}
                  value={values.alamat || ''}
                  error={this.hasError('alamat')}
                  helperText={
                    this.hasError('alamat') ? errors.alamat[0] : null
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} style={{ textAlign: "left" }}>
            <Button onClick={this.props.onCancel} >Batal</Button> &nbsp;
            <Button type="primary" htmlType="submit" icon={<SaveOutlined />} loading={loadingBtn} onClick={this.onSubmit} style={{ float: "right" }}>Simpan</Button>
          </Col>
        </Row>
      </Row>
    )
  }
}


export default DataDiriForm;