import React from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { Row, Col, PageHeader, BackTop, Button, Breadcrumb, message } from 'antd';
import { DoubleRightOutlined, SolutionOutlined } from '@ant-design/icons';

import { isAuth, _setAxios, getParamTable, getParamTempTableFiltering } from '../../../../lib/Helper';

import AuthRedirect from '../../../../components/redirect/AuthRedirect'
import Filter from '../modal/Filter';
import Desktop from './Desktop';
import Mobile from './Mobile';
import SearchTable from '../../../../components/search-table/SearchTable';
import SortingTableMobileWithFilter from '../../../../components/sorting-table/SortingTableMobileWithFilter';
import SortingTableDesktopWithFilter from '../../../../components/sorting-table/SortingTableDesktopWithFilter';

const sortFields = [{
  value: "id",
  label: "Tanggal Pembuatan",
}, {
  value: "tgl_transaksi",
  label: "Tanggal Pembayaran",
}, {
  value: "nominal_tagihan",
  label: "Nominal Tagihan",
}, {
  value: "nominal_pembayaran",
  label: "Nominal Pembayaran",
}]

class PembayaranSiswa extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      values: {},
      table: {
        loading_first: false,
        loading: false,
        data: [],
        search: "",
        pagination: {
          current: 1,
          pageSize: 10,
          total: null,
        },
        sorting: [],
        filtering: {
          siswa_id: this.props.privilege.profile_ex.siswa_id,
        },
      },
      filter: {
        values: {
        },
        visible: false,
      },
    };
  }

  fetchTable = (isNew = false) => {
    const { table } = this.state
    const params = getParamTable("default", table)
    this.fetch(params, isNew);
  }

  fetch = async (params = {}, isNew = false) => {
    console.log("this.props.privilege.profile_ex.siswa_id", this.props.privilege.profile_ex.siswa_id)
    this.setState({
      table: {
        ...params,
        loading_first: isMobile ? (isNew ? true : false) : false,
        loading: true
      }
    });

    params = {
      ...params,
      filtering: {
        ...params.filtering,
      },
      pagination: {
        ...params.pagination,
        current: isMobile ? (isNew ? 1 : params.pagination.current) : params.pagination.current
      }
    }
    _setAxios("keuangan/pemasukan/pembayaran/table", "POST", params).then(resp => {

      if (resp.status === true) {
        let data = isNew ? [] : this.state.table.data

        let current = params.pagination.current
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
            data: isMobile ? [...data, ...resp.data.list] : resp.data.list,
            pagination: {
              ...params.pagination,
              current: isMobile ? current + 1 : current,
              total: resp.data.info.total
            }
          }
        });
      } else {
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
          }
        })
      }
    })
  };

  handleChangeSetValue = (name, value) => {
    const { values } = this.state;
    this.setForm(values, name, value)
  };

  setForm = (values, name, value) => {
    values[name] = value;

    this.setState({
      values: values,
    });
  }

  modalForm = (visible = false, data = {}) => {
    if (!this.state.global_filter.values.siswa_id) {
      message.warning("Pilih siswa terlebih dahulu")
      return
    }
    this.setState({
      form: {
        data: {
          ...data,
          siswa_id: this.state.global_filter.values.siswa_id ? this.state.global_filter.values.siswa_id : null,
        },
        visible: visible
      },
    })
  }

  modalDelete = (visible = false, data = {}) => {
    this.setState({
      delete: {
        data: data,
        visible: visible
      },
    })
  }

  modalExport = (visible = false) => {
    if (!this.state.global_filter.values.siswa_id) {
      message.warning("Pilih siswa terlebih dahulu")
      return
    }
    this.setState({
      export: {
        ...this.state.export,
        visible: visible,
      },
    })
  }

  // FILTER START ------------------------------------------
  setTempTableFilter = (name, value) => {

    let values = this.state.filter.values
    values[name] = value

    this.setState({
      filter: {
        ...this.state.filter,
        values: values
      }
    })
  };

  setGlobalFilter = (name, value) => {

    let values = this.state.global_filter.values
    values[name] = value

    this.setState({
      global_filter: {
        ...this.state.global_filter,
        values: values
      }
    })
  };

  setTableFilterFromTemp = () => {
    const params = getParamTempTableFiltering(this.state.table, this.state.filter.values)
    this.fetch(params, isMobile ? true : false)
    this.modalFilter(false)
  };

  modalFilter = (visible = false) => {
    if (!this.state.global_filter.values.siswa_id) {
      message.warning("Pilih siswa terlebih dahulu")
      return
    }
    this.setState({
      filter: {
        ...this.state.filter,
        visible: visible,
        values: {
          status: null,
        }
      },
    })
  }
  // FILTER END ------------------------------------------

  render() {

    const access = this.props.privilege.access["/keuangan/pembayaran-siswa"]
    if (!isAuth(this.props.privilege) || access === undefined) {
      return <AuthRedirect />
    }

    const { table } = this.state;

    return (
      <>
        <PageHeader
          style={{
            padding: 0
          }}
          onBack={() => window.history.back()}
          subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
            <Breadcrumb.Item>Keuangan</Breadcrumb.Item>
            <Breadcrumb.Item>Pembayaran Siswa</Breadcrumb.Item>
          </Breadcrumb>}
        />
        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
          <PageHeader
            className="site-page-header"
            title="Pembayaran Siswa"
            subTitle={!isMobile ? `Data Pembayaran Siswa` : ``}
          />
          <Row gutter={[16, 16]}>

            <Col xs={24}>
              <Button><Link to={`pembayaran-siswa/tagihan`}><SolutionOutlined /> Tagihan Siswa</Link></Button>
            </Col>

            {/* SEARCH LIST/TABLE */}
            <Col xs={24} sm={18} md={19} >
              <SearchTable table={table} fetch={this.fetch} />
            </Col>

            {/* SORTING & FILTER LIST/TABLE */}
            <Col xs={24} sm={6} md={5}>
              <SortingTableMobileWithFilter isFilter={true} table={table} fetch={this.fetch} modalFilter={this.modalFilter} sortFields={sortFields} />
              <SortingTableDesktopWithFilter table={table} fetch={this.fetch} modalFilter={this.modalFilter} sortFields={sortFields} />
            </Col>
            {/* LIST/TABLE DATA */}
            <Col xs={24}>
              <BrowserView>
                <Desktop
                  access={access}
                  state={this.state}
                  fetch={this.fetch}
                  fetchTable={this.fetchTable}
                />
              </BrowserView>
              <MobileView>
                <Mobile
                  access={access}
                  state={this.state}
                  fetch={this.fetch}
                  fetchTable={this.fetchTable}
                />
              </MobileView>
            </Col>
          </Row>
        </div >

        {
          this.state.filter.visible ?
            <Filter
              visible={this.state.filter.visible}
              hideModal={this.modalFilter.bind(this, false)}
              setTempTableFilter={this.setTempTableFilter}
              setTableFilterFromTemp={this.setTableFilterFromTemp}
            /> : ""
        }
        <BackTop />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(PembayaranSiswa);
